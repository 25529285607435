import PropTypes from 'prop-types'
import React from 'react'
import Article from '../Article/Article'
import Services from '../Services/Services'
import Contact from '../Contact/Contact'
import { graphql, StaticQuery } from 'gatsby'

class Main extends React.Component {
    render() {
        let close = (
            <div
                className="close"
                role="button"
                aria-label="Button"
                tabIndex={0}
                onClick={() => {
                    this.props.onCloseArticle()
                }}
                onKeyDown={() => {
                    this.props.onCloseArticle()
                }}
            />
        )

        return (
            <div
                ref={this.props.setWrapperRef}
                id="main"
                style={
                    this.props.timeout
                        ? { display: 'flex' }
                        : { display: 'none' }
                }
            >
                {this.props.data.wordpress.page.menuPrincipal.menuPrincipal &&
                this.props.data.wordpress.page.menuPrincipal.menuPrincipal.map(
                    (article, index) => {
                            return (
                                article.lien !== '#services' ?
                                <Article
                                    props={this.props}
                                    section={article.lien}
                                    title={article.label}
                                    close={close}
                                    key={index}
                                />
                                : null
                            )
                    },
                )}
                <Services
                    props={this.props}
                    section="#services"
                    title="Services"
                    close={close}
                />
                <Contact
                    props={this.props}
                    section="#contact"
                    title="Contact"
                    close={close}
                />
            </div>
        )
    }
}

Main.propTypes = {
    route: PropTypes.object,
    article: PropTypes.string,
    articleTimeout: PropTypes.bool,
    onCloseArticle: PropTypes.func,
    timeout: PropTypes.bool,
    setWrapperRef: PropTypes.func.isRequired,
}
export default props => (
    <StaticQuery
        query={graphql`
            query Article {
                wordpress {
                    page(id: "2", idType: DATABASE_ID) {
                        menuPrincipal {
                            menuPrincipal {
                                label
                                lien
                            }
                        }
                    }
                }
            }
        `}
        render={data => <Main data={data} {...props} />}
    />
)
