import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

class Header extends Component {
    constructor(props) {
        super(props)
        this.image = this.props.data.file.childImageSharp.fluid
    }

    render() {
        return (
            <header
                id="header"
                style={this.props.timeout ? { display: 'none' } : {}}
            >
                <div className="logo">
                    <Img fluid={this.image} imgStyle={{ borderRadius: 60 }} />
                </div>
                <div className="content">
                    <div className="inner">
                        <h3>
                            {
                                this.props.data.wordpress.generalSettings.title
                            }
                        </h3>
                        <h1>
                            {
                                this.props.data.wordpress.generalSettings.description
                            }
                        </h1>
                        <p className={'slogan'}>
                            {
                                this.props.data.wordpress.page.presentation.texteDaccroche
                            }
                        </p>
                        <hr />
                        <p className={'technos'}>
                            {
                                this.props.data.wordpress.page.presentation.technos
                            }
                        </p>
                        <button
                            onClick={() => {
                                this.props.onOpenArticle('#contact')
                            }}
                        >
                            {
                                this.props.data.wordpress.page.presentation.texteDuBoutonDappelALaction
                            }
                        </button>
                    </div>
                </div>
                <nav>
                    <ul>
                        <li>
                            <a
                                href="#!"
                                onClick={() => {
                                    this.props.onOpenArticle('#a-propos')
                                }}
                            >
                                À propos
                            </a>
                        </li>
                        <li>
                            <a
                                href="#!"
                                onClick={() => {
                                    this.props.onOpenArticle('#services')
                                }}
                            >
                                Services
                            </a>
                        </li>
                        <li>
                            <a
                                href="#!"
                                onClick={() => {
                                    this.props.onOpenArticle('#projets')
                                }}
                            >
                                Réalisations
                            </a>
                        </li>
                        <li>
                            <a
                                href="#!"
                                onClick={() => {
                                    this.props.onOpenArticle('#temoignages')
                                }}
                            >
                                Témoignages
                            </a>
                        </li>
                        <li>
                            <a
                                href="#!"
                                onClick={() => {
                                    this.props.onOpenArticle('#mentions-legales')
                                }}
                            >
                                Mentions Légales
                            </a>
                        </li>
                    </ul>
                </nav>
            </header>
        )
    }
}

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool,
}

export default props => (
    <StaticQuery
        query={graphql`
            query Header {
                wordpress {
                    generalSettings {
                        title
                        description
                    }
                    page(id: "2", idType: DATABASE_ID) {
                      presentation {
                        texteDaccroche
                        texteDuBoutonDappelALaction
                        technos
                      }
                    }
                }
                file(relativePath: { eq: "logo_ecw.png" }) {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `}
        render={data => <Header data={data} {...props} />}
    />
)
