import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import ImageProfil from '../ImageProfil/ImageProfil'
import Temoignages from '../Temoignages/Temoignages'
import Projets from '../Projets/Projets'

class Article extends Component {
    constructor(data) {
        super(data)
        this.data = data
        this.pageId = this.data.pageId
        this.state = {
            article: null,
            articleTimeout: null,
        }
        this.componentDidUpdate = this.componentDidUpdate.bind(this)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.props.article !== this.props.props.article) {
            this.setState({
                article: this.props.props.article,
            })
        }

        if (
            prevProps.props.articleTimeout !== this.props.props.articleTimeout
        ) {
            this.setState({
                articleTimeout: this.props.props.articleTimeout,
            })
        }
    }

    render() {
        return (
            <article
                id={this.state.article}
                className={`${
                    this.state.article === this.data.section ? 'active' : ''
                } ${this.state.articleTimeout ? 'timeout' : ''}`}
                style={{ display: 'none' }}
            >
                <Typography className={'major'} variant={'h4'} component={'h2'}>
                    {this.data.title}
                </Typography>
                {/*A Propos*/}
                {this.data.section === '#a-propos' ? (
                    <>
                        <ImageProfil
                            imageProfil={
                                this.data.data.wordpress.page.about.imageDeProfil
                            }
                        />
                        <Typography
                            component={'div'}
                            paragraph
                            gutterBottom
                            className={'temoignage'}
                            dangerouslySetInnerHTML={{
                                __html: this.data.data.wordpress.page.about.texteDePresentation,
                            }}
                        />
                    </>
                ) : (
                    ''
                )}

                {/*Réalisations*/}
                {this.data.section === '#projets' ? (
                    <Projets
                        projets={
                            this.data.data.wordpress.projects.nodes
                        }
                        title={this.data.title}
                    />
                ) : (
                    ''
                )}

                {/*Temoignages*/}
                {this.data.section === '#temoignages' ? (
                    <Temoignages
                        temoignages={
                            this.data.data.wordpress.page.testimonies.listeDeTemoignages
                        }
                        title={this.data.title}
                    />
                ) : (
                    ''
                )}

                {/*Mentions Légales*/}
                {this.data.section === '#mentions-legales'
                    ? <Typography
                        component="p"
                        paragraph
                        dangerouslySetInnerHTML={{
                            __html: this.data.data.wordpress.page.mentionsLegales.texteDesMentionsLegales
                        }}
                    />
                    : ''}

                {this.data.close}
            </article>
        )
    }
}

export default props => (
    <StaticQuery
        query={graphql`
            query Tabs {
                wordpress {
                    page(id: "2", idType: DATABASE_ID) {
                        about {
                            texteDePresentation
                            imageDeProfil {
                                sourceUrl
                                altText
                            }
                        }
                        testimonies {
                            listeDeTemoignages {
                                ...Temoignages
                            }
                        }
                        mentionsLegales {
                            texteDesMentionsLegales
                        }
                    }
                    projects {
                        nodes {
                             title
                             projects {
                                urlDuProjet
                                descriptionDuProjet
                             }
                             content
                             featuredImage {
                                 node {
                                 sourceUrl
                                 altText
                                }
                             }
                        }
                    }
                }
                allImageSharp(filter: {fixed: {originalName: {eq: "profil.png"}}}) {
                    nodes {
                        fixed (height: 300, width: 300, quality: 100) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                    }
                }
            }
        `}
        render={data => <Article data={data} {...props} />}
    />
)
