import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';

class Contact extends Component {
    constructor(data) {
        super(data);
        this.data = data;
        this.state = {
            article: null,
            articleTimeout: null,
        };
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.props.article !== this.props.props.article) {
            this.setState({
                article: this.props.props.article,
            });
        }

        if (
            prevProps.props.articleTimeout !== this.props.props.articleTimeout
        ) {
            this.setState({
                articleTimeout: this.props.props.articleTimeout,
            });
        }
    }
    render() {
        return (
            <article
                id={this.state.article}
                className={`${
                    this.state.article === this.data.section ? 'active' : ''
                } ${this.state.articleTimeout ? 'timeout' : ''}`}
                style={{ display: 'none' }}
            >
                <Typography className={'major'} variant={'h4'} component={'h2'}>
                    Contact
                </Typography>
                <form
                    name="contact-form"
                    method="POST"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    action="/succes"
                >
                    <input
                        type="hidden"
                        name="form-name"
                        value="contact-form"
                    />
                    <input type="hidden" name="bot-field" />
                    <label>
                        Votre Nom
                        <input type="text" name="name" id="name" required />
                    </label>
                    <label>
                        Votre Email
                        <input type="email" name="email" id="email" required />
                    </label>
                    <label>
                        Votre Message
                        <textarea
                            name="message"
                            id="message"
                            rows="5"
                            required
                        />
                    </label>
                    <button type="submit">Envoyer</button>
                </form>
                {this.data.close}
            </article>
        );
    }
}

export default Contact;
