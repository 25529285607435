import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import BuildIcon from '@material-ui/icons/Build';
import SearchIcon from '@material-ui/icons/Search';

class Services extends Component {
    constructor(data) {
        super(data);
        this.data = data;
        this.state = {
            article: null,
            articleTimeout: null,
        };
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.props.article !== this.props.props.article) {
            this.setState({
                article: this.props.props.article,
            });
        }

        if (
            prevProps.props.articleTimeout !== this.props.props.articleTimeout
        ) {
            this.setState({
                articleTimeout: this.props.props.articleTimeout,
            });
        }
    }

    iconChoice(ID) {
        const mainColor = '#c4b583';
        if (ID === 'CRÉATION') {
            return <LaptopMacIcon fontSize={'large'} htmlColor={mainColor} />;
        } else if (ID === 'RÉFÉRENCEMENT') {
            return <SearchIcon fontSize={'large'} htmlColor={mainColor} />;
        } else if (ID === 'MAINTENANCE') {
            return <BuildIcon fontSize={'large'} htmlColor={mainColor} />;
        }
    }

    render() {
        return (
            <article
                id={this.state.article}
                className={`${
                    this.state.article === this.data.section ? 'active' : ''
                } ${this.state.articleTimeout ? 'timeout' : ''}`}
                style={{ display: 'none' }}
            >
                <Typography className={'major'} variant={'h4'} component={'h2'}>
                    {this.data.title}
                </Typography>
                <Grid
                    container
                    justify={'center'}
                    spacing={3}
                    className={'services-grid'}
                >
                    {this.data.data.wordpress.page.services &&
                    this.data.data.wordpress.page.services.servicesProposes.map(
                            (service, index) => {
                                return (
                                    <Grid item xs={12} sm={6} key={index}>
                                        <Paper className={'services-item'}>
                                            {this.iconChoice(
                                                service.nomDuService
                                            )}
                                            <Typography
                                                component="h3"
                                                variant={'h6'}
                                                color={'inherit'}
                                                align={'center'}
                                                gutterBottom
                                            >
                                                {service.nomDuService}
                                            </Typography>
                                            <hr />
                                            <Typography
                                                component="div"
                                                paragraph
                                                gutterBottom
                                                dangerouslySetInnerHTML={{
                                                    __html: service
                                                        .descriptionDuService
                                                }}
                                            />
                                        </Paper>
                                    </Grid>
                                );
                            }
                        )}
                </Grid>

                {this.data.close}
            </article>
        );
    }
}

export default props => (
    <StaticQuery
        query={graphql`
            query Services {
                wordpress {
                    page(id: "2", idType: DATABASE_ID) {
                        services {
                            servicesProposes {
                                nomDuService
                                descriptionDuService
                            }
                        }
                    }
                }
            }
        `}
        render={data => <Services data={data} {...props} />}
    />
);
