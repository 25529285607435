import React, { Component } from 'react';

class ImageProfil extends Component {
    render() {
        const { imageProfil } = this.props;
        return imageProfil !== null ? (
            <span className="image main">
                <img src={imageProfil.sourceUrl} alt={imageProfil.altText} />
            </span>
        ) : (
            ''
        );
    }
}

export default ImageProfil;
