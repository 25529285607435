import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { Paper, Typography } from '@material-ui/core';
import FormatQuoteIcon from '@material-ui/core/SvgIcon/SvgIcon';

class Temoignages extends Component {
    render() {
        const { temoignages } = this.props;
        return temoignages !== null
            ? temoignages.map((temoignage, index) => {
                  return (
                      <Paper className={'container-temoignage'} key={index}>
                          <FormatQuoteIcon
                              fontSize={'large'}
                              htmlColor={'#c4b583'}
                          />
                          {temoignage.texteDuTemoignage && (
                              <Typography
                                  component={'div'}
                                  paragraph
                                  gutterBottom
                                  className={'temoignage'}
                              >
                                  {temoignage.texteDuTemoignage}
                              </Typography>
                          )}
                          {temoignage.auteur && (
                              <Typography
                                  component={'p'}
                                  variant={'caption'}
                                  gutterBottom
                                  align={'right'}
                                  className={'auteur'}
                                  dangerouslySetInnerHTML={{
                                      __html: temoignage.auteur
                                  }}
                              />
                          )}
                      </Paper>
                  );
              })
            : '';
    }
}

export default Temoignages;

export const query = graphql`
    fragment Temoignages on WORDPRESS_Page_Testimonies_listeDeTemoignages
    {
        auteur
        poste
        texteDuTemoignage
    }
`;
