import React, { Component } from 'react'
import {
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
    Link,
} from '@material-ui/core'

class Projets extends Component {
    render() {
        const { projets } = this.props
        return (
            <>
                {/*Projets*/}
                {projets &&
                projets.map((projet, index) => {
                    return (
                        <Card className={'card'} key={index}>
                            <CardActionArea>
                                <Link
                                    href={projet.projects.urlDuProjet}
                                    underline={'none'}
                                    target={'_blank'}
                                    rel={'noopener'}
                                >
                                    <CardMedia
                                        className={'card-image'}
                                        image={projet.featuredImage.node.sourceUrl}
                                    />
                                    <CardContent>
                                        <Typography
                                            variant={'h6'}
                                            component={'h6'}
                                            className={'major'}
                                        >
                                            {projet.title}
                                        </Typography>
                                        <Typography
                                            component={'div'}
                                            className="projet-description"
                                            paragraph
                                            gutterBottom
                                            dangerouslySetInnerHTML={{
                                                __html: projet.projects.descriptionDuProjet
                                            }}
                                        />
                                    </CardContent>
                                </Link>
                            </CardActionArea>
                        </Card>
                    )
                })}
            </>
        )
    }
}

export default Projets

// export const query = graphql`
//     fragment Project on WORDPRESS_RootQueryToProjectConnection {
//         nodes {
//             title
//             projects {
//                 urlDuProjet
//             }
//             content
//             featuredImage {
//                 node {
//                     sourceUrl
//                 }
//             }
//         }
//
//     }
// `
